import React , { useEffect, useState, useRef } from "react";
import Contato from "../../components/contato/lista/index.jsx";
import api_horse from '../../services/api_horse.js';
import Navbar from "../../components/navbar/index.jsx";
import Menu from "../../components/menu/menu.jsx";
//import {StatusRequisicao} from "../../../services/functions.js";
import ContatoModal from "../../components/contato/modal/index.jsx";
import "./contato.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable  , ptBR  } from "primereact/datatable" ;
import { Column } from "primereact/column";
import { Toolbar } from "primereact/toolbar"
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Tag } from 'primereact/tag';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';

         

function Contatos(){

    const [contatos, setContatos] = useState([]);
    const [isRequisicaoOpen, setIsRequisicaoOpen] = useState(false);
    const [dados_categoria, setDados_Categoria] = useState({requisicao: 0, pdf64: ""});
    const [status, setStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [contatoDialog, setContatoDialog] = useState(false);
    const [deleteContatoDialog, setDeleteContatoDialog] = useState(false);
    const [contato, setContato] = useState(null);
    const [selectedContatos, setSelectedContatos] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);


    const getSeverity = (status) => {
        switch (status) {
            case 'Aguardando':
                return 'danger';

            case 'Finalizado':
                return 'success';
        }
    };


    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => editContato(rowData)} />
                <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => editContato(rowData)} />
            </React.Fragment>
        );
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Novo" icon="pi pi-plus" severity="success" onClick={editContato} />
            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={editContato} />;
    };


    const editContato = (contato) => {
        setContato({ ...contato });
        setContatoDialog(true);
    };

    const confirmDeleteProduct = (contato) => {
        setContato(contato);
        setDeleteContatoDialog(true);
    };


    const formatDate = (value) => {
        return value.toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };
    const DataExameBodyTemplate = (rowData) => {
        const data_exame = new Date(rowData.created_at.substring(0, 19)); 
        return ( formatDate(data_exame) );
    };

    const StatusBodyTemplate = (rowData) => {
     //   return <Tag value={StatusRequisicao(rowData.status)} severity={getSeverity(StatusRequisicao(rowData.status))} />;
    };
    
    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yyyy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };


    function ListarRequisicoes(){
        api_horse.post('/v1/contato/listar', {
            empresa: localStorage.getItem('id_empresa') ,
        })
        .then(response => setContatos(response.data))
        .catch(err => console.log(err));
    }

    function openModalRequisicao(requisicao){

        if (requisicao > 0) {
            api_horse.get(`v1/contat/${requisicao}`)
            .then(response => {

                setDados_Categoria(response.data);
                setLoading(true);
                setIsRequisicaoOpen(true);   

                api_horse.post('v1/auditoria/'  , {
                    vfuncionario: localStorage.getItem('sessionId'),
                    vdata: '',
                    vhora: '08:08',
                    vtabela: 'web',
                    voperacao: 'Impressão Laudo web',
                    videntificador: 'Impressão Laudo web ' + requisicao
                }) 
                      
            })
            .catch(err => console.log(err));
        } else {
            setDados_Categoria([]);
            setIsRequisicaoOpen(true);
        }
        
    }

    function closeModalRequisicao(){
        setIsRequisicaoOpen(false);
        setLoading(false);
        ListarRequisicoes();
    }

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            apelido: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            medico: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            created_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            medico: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            dor: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
        });
        setGlobalFilterValue('');
    };


    const renderHeader = () => {
        return (
            <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
                <h5 className="m-0">Pesquisar ... </h5>
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Pesquisar ... " />
                </IconField>
            </div>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <IconField iconPosition="left">
                <InputIcon className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="pesquisando..." />
            </IconField>
        </div>
    );
    
    useEffect(() => ListarRequisicoes(), []);

    return <div className="container-fluid mt-page">

    <Navbar />

    <div className="row flex-nowrap">
    <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 mt-50">

        <Menu page="contato" />
    </div>

    <div className="col py-3 me-3 ms-10">

       
        <ContatoModal isOpen={isRequisicaoOpen} 
                       dados_categoria={dados_categoria}        
                       onRequestClose={closeModalRequisicao}
                        />

        <div className="container-fluid mt-page form-requisicao">
        <div className="table-wrapper">

        <div>
            <Toast ref={toast} />
            <div className="card">
            <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            <DataTable  ref={dt} locale="pt_BR" value={contatos} header={header} responsiveLayout="scroll" size="small" removableSort paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ Width: '100%' }}
            selectionMode="checkbox" selection={selectedContatos} onSelectionChange={(e) => setSelectedContatos(e.value)}
            filters={filters} filterDisplay="menu" globalFilterFields={['apelido', 'nivel_interesse','telefone']}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" globalFilter={globalFilter} header={header}>

            <Column field="apelido" header="Nome Paciente"  filter filterPlaceholder="Procurar por nome" sortable style={{ width: '15%' }} >  </Column>
            <Column field="created_at" Column header="Data " filterField="created_at" dataType="date"  style={{ width: '10%' }}  body={DataExameBodyTemplate} filter filterElement={dateFilterTemplate}   />	
            <Column field="telefone" header="Telefone"  style={{ width: '15%' }} ></Column>
            <Column field="nivel_interesse" header="Interesse" filterMenuStyle={{ width: '1rem' }}   style={{ width: '10%' }}   />
            <Column async  body={actionBodyTemplate} exportable={false} style={{ width: '10%' }}> </Column>
            </DataTable>      
            </div>
            </div>  
            </div>
            </div>
        </div>   
        </div>   
        </div>                    
}

export default Contatos;