import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard/dashboard.jsx";
import Negocio from "./pages/negocio/negocio.jsx"
import PrePaciente from "./pages/pacientepre/pacientepre.jsx"
import Registro from "./pages/registro/registro.jsx";
import Login from "./pages/login/login.jsx";
import PrivateRoute from "./components/private-route/private-route.jsx";
import Contatos from "./pages/contato/contato.jsx";

function Rotas(){
    return <BrowserRouter>
        <Routes>
            <Route path="/registro" element={<Registro />} />
            <Route path="/login" element={<Login />} />

            <Route path="/dashboard" element={
                <PrivateRoute>
                    <Dashboard />
                </PrivateRoute>
            } />

            <Route path="/" element={
                <PrivateRoute>
                    <Dashboard />
                </PrivateRoute>
            } />

            <Route path="/negocios" element={
                <PrivateRoute>
                    <Negocio />
                </PrivateRoute>
            } />

            <Route path="/prepaciente" element={
                <PrivateRoute>
                    <PrePaciente />
                </PrivateRoute>
            } />

            <Route path="/contato" element={
                <PrivateRoute>
                    <Contatos />
                </PrivateRoute>
            } />

        </Routes>
    </BrowserRouter>
}

export default Rotas;