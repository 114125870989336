import axios from "axios";

const URL = "https://api.glas.com.br:215/srv";

const api_horse = axios.create({
    baseURL: URL,
    auth: {
        username: "infotec",
        password: "inf*251270"    
    }
});

export default api_horse;